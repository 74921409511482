<template>
  <div class="bsc-quick-add-cart-standard">
    <!-- ios18遮罩 -->
    <div
      v-show="visible && isIOS18"
      class="bsc-quick-add-cart-standard__mask"></div>
    <!-- 人气氛围 -->
    <QuickAtmosphereFlow
      v-if="atmosphereFlowVisible"
      ref="quickAtmosphereFlowRef"
      :product-info="productInfo"
      :location="`quickaddtobag`"
      :will-sold-out-tips-info="willSoldOutTipsInfo"
      :fs-abt="fsAbt"
      :hot-news="productLabel?.hotNews" />
    <Drawer
      :visible.sync="visible"
      custom-class="bsc-quick-add-cart-standard-drawer"
      :lock-scroll="!isIOS18"
      :modal="!isIOS18"
      max-size="80vh"
      @close="closeQuickAddCart">
      <div
        class="bsc-quick-add-cart"
        data-customSceneName="bsc-quick-add-cart"
        :class="featureConfig?.customClass">
        <!-- header -->
        <div class="bsc-quick-add-cart__header">
          <!-- title -->
          <div
            ref="close"
            class="bsc-quick-add-cart__close-btn"
            tabindex="0"
            role="button"
            :aria-label="language?.SHEIN_KEY_PWA_15567"
            @click="closeQuickAddCart">
            <Icon
              name="sui_icon_nav_close_24px"
              width="4.28vw"
              height="4.28vw" />
          </div>
        </div>
        <!-- body -->
        <div class="bsc-quick-add-cart__body">
          <template v-if="productInfo && saleAttr">
            <div class="bsc-quick-add-cart__content">
              <Gallery
                ref="gallerySwiper"
                :index="state.imgModalIndex"
                :fixed-ratio="state.fixedRatio"
                :loading="isLoading"
                :is-switch-color="state.isSwitchColor"
                :middle-size-mode="largeImageMode && mainSalesAttrConfig.isShowMainAttr"
                :gb-common-info="gbCommonInfo"
                :imgs="goodsImgs"
                :lazy-image="lazyImage"
                :openImgModal="openImgModal" />
              <GoodsName
                :needClickToDetail="featureConfig?.needClickToDetail"
                :detailUrlExtendParam="detailUrlExtendParam"
                :analysisConfig="analysisConfig"
                :mallCode="mallCode"
                :language="language"
                :product-info="productInfo"
                :fixed-ratio="state.fixedRatio"
                :price-info="priceInfo"
                :sale-attr="staticSaleAttr"
                :gb-common-info="gbCommonInfo"
                :skuInfo="state.skuInfo"
                @click-details="handleJumpDetailsPage" />
              <!-- 自定义价格组件 -->
              <component
                :is="customPriceComponent"
                v-if="customPriceComponent?.render()" />
              <template v-else>
                <PriceContainer
                  :featureConfig="featureConfig"
                  :fs-abt="fsAbt"
                  :isSoldOut="isSoldOut"
                  :currentStock="currentStock"
                  :skuInfo="state.skuInfo"
                  :skuList="skuList"
                  :price="price"
                  :goodsId="productInfo?.goods_id"
                  @clickEstimatedTag="onHandlerClickEstimatedTag" />
                <PriceTips
                  :mallCode="mallCode"
                  :featureConfig="featureConfig"
                  :skuInfo="state.skuInfo"
                  :isSoldOut="isSoldOut"
                  :currentStock="currentStock"
                  @closeQuickAddCart="priceTipscloseQuickAddCart" />
              </template>
              <!-- 主销售属性 -->
              <BMainSalesAttr
                v-if="mainSalesAttrConfig.isShowMainAttr"
                :goodsId="productInfo?.goods_id"
                :config="mainSalesAttrConfig"
                :dataSource="mainSalesAttrData"
                :topState="{
                  fixedRatio: state.fixedRatio,
                }"
                @onMainAttrChange="onHandlerMainAttrChange"
                @onLargeImageChange="onHandlerLargeImageChange" />
              <QuickSize
                ref="quickSizeRef"
                :visible="visible"
                :callbacks="callbacks"
                :salesAttrConfig="salesAttrConfig"
                :sourceIdentifier="sourceIdentifier"
                :analysisConfig="analysisConfig"
                :defaultCountryCode="defaultCountryCode"
                :currentCountry="currentCountry"
                :currentUnit="currentUnit"
                :stockTipShowLocation="stockTipShowLocation"
                :skuInfo="state.skuInfo"
                :mallCode="mallCode"
                :willSoldOutTipsInfo="willSoldOutTipsInfo"
                :closeQuickAddCart="closeQuickAddCart"
                :handleCompose="handleCompose"
                :handleMallClick="handleMallClick"
                :handleLocalChange="handleLocalChange"
                :handleOpenSizeGuide="handleOpenSizeGuide"
                :handleClickSizeGroupItem="handleClickSizeGroupItem"
                :handleUpdateQuickShip="setQuickShip" />
              <!-- 销售属性下方区域 -->
              <component
                :is="belowSalesAttrSlot"
                v-if="belowSalesAttrSlot?.render()" />
              <Quantity
                v-if="qtySelectShow"
                :goodsId="productInfo?.goods_id"
                :currentStock="currentStock"
                :willSoldOutTipsInfo="willSoldOutTipsInfo"
                :buyMultipleText="buyMultipleText"
                :quantity="state.quantity"
                :setQuantity="setQuantity" />
              <!-- blank-placeholder -->
              <div class="blank-placeholder"></div>
            </div>
            <!-- footer -->
            <FooterBar
              :sourceIdentifier="sourceIdentifier"
              :estimatedInfo="estimatedInfo"
              :mainPrice="mainPrice"
              :fixedRatio="state.fixedRatio"
              :isMainProduct="isMainProduct"
              :callbacks="callbacks"
              :checkSelectSize="checkSelectSize"
              :largeImageMode="largeImageMode"
              :language="language"
              :isSoldOut="isSoldOut"
              :featureConfig="featureConfig"
              :productInfo="productInfo"
              :setLoading="setLoading"
              :quantity="state.quantity"
              :analysisConfig="analysisConfig"
              :dataSource="dataSource"
              :skuInfo="state.skuInfo"
              :loading="isLoading"
              :mallStock="currentStock"
              :quickShip="state.quickShip"
              :mallCode="mallCode"
              :closeQuickAddCart="closeQuickAddCart"
              @onChangeLimitType="onChangeLimitType"
              @onAddCartFinished="onAddCartFinished"
              @onAddCartBefore="onAddCartBefore"
              @onLoginStatusChange="onLoginStatusChange"
              @onWishStatusChange="onWishStatusChange" />
          </template>
          <!-- 骨架屏 -->
          <template v-else>
            <PlaceholderGoodsInfo
              :image-list="[extendedParam?.mainImg || '', '', '']"
              :lazy-image="lazyImage" />
          </template>
        </div>
        <!-- 兼容ios地址栏收缩的占位元素 -->
        <div
          id="bsc-quick-add-cart__toolbar-placeholder"
          class="bsc-quick-add-cart__toolbar-placeholder"
          :class="{'ios-safe-bottom': isIOS18}"
        >
        </div>
      </div>
    </Drawer>
    <!-- 加车弹窗挂载位置 -->
    <component
      :is="dialogComponent"
      ref="dialogRef" />
    <!-- 大图模式 -->
    <ImgModal
      v-model="state.showImgModal"
      :index="state.imgModalIndex"
      :gb-common-info="gbCommonInfo"
      :product-info="productInfo"
      :fs-abt="fsAbt"
      :will-sold-out-tips-info="willSoldOutTipsInfo"
      :imgs="goodsImgs" />
    <SizeGuide
      v-model="state.sizeGuideVisible"
      :language="language"
      :product-info="productInfo"
      :product-label="productLabel"
      :sale-attr="staticSaleAttr"
      :country="currentCountry"
      :size-unit="currentUnit"
      :fs-abt="fsAbt"
      @local-change="handleLocalChange"
      @size-unit-change="handleSizeUnitChange" />
  </div>
</template>

<script name="BQuickAddCartStandard" setup lang="ts">
import { DS_QuickAddCartStandard, AS_QuickAddCartStandard } from '@shein-aidc/bs-quick-add-cart-standard-mobile-vue2'
import { BMainSalesAttr, type DS_MainSalesAttr } from '@shein-aidc/bs-main-sales-attr-mobile-vue2'
import { Drawer } from '@shein/sui-mobile'
import { provide, ref, computed, reactive, nextTick, watch } from 'vue'
import { clearModules, updateModules, updateProductWishStatus, useModules } from './modules/useModules'
import { useMainSalesAttrModules } from './modules/useMainSalesAttrModules'
import { useGoodsImgs } from './hook/useGoodsImgs'
import PlaceholderGoodsInfo from './components/PlaceholderGoodsInfo.vue'
import { Icon } from '@shein-aidc/icon-vue2'
import { IHandlerResult } from './components/FooterBar/types.ts'
import { useAnalysis } from '../../common/analysisSource'
import GoodsName from './components/GoodsName.vue'
import { callHook } from './components/FooterBar/utils'
import PriceContainer from './components/PriceContainer/index.vue'
import PriceTips from './components/PriceTips/index.vue'
import { useEstimatedInfo, useCommonInfo } from './hook/common.ts'
import QuickSize from './components/QuickSize/index.vue'
import Quantity from './components/Quantity/index.vue'
import ImgModal from './components/ImgModal/index.vue'
import Gallery from './components/Gallery/index.vue'
import QuickAtmosphereFlow from './components/QuickAtmosphereFlow/index.vue'
import {
  DEFAULT_FEATURE_CONFIG,
  DEFAULT_ANALYSIS_CONFIG,
  DEFAULT_SALES_ATTR_CONFIG,
} from './hook/useQuickAddCart'
import { LRUCache } from './utils/cache'
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
import { getSource } from '../../common/dataSource'
import FooterBar from './components/FooterBar/index.vue'
import { ESTIMATED_DATA_TYPES } from './hook/utils.ts'
import AddCartSuccessDialog from './components/Dialog/AddCartSuccess.vue'
import AddCartFailDialog from './components/Dialog/AddCartFail.vue'
// import VipExplanation from './components/Dialog/VipExplanation.vue'
import PromotionLimit from './components/Dialog/PromotionLimit.vue'
import { getImageRatio } from './utils/image'
import { useDialog } from './hook/useDialog.ts'
import SizeGuide from '@shein-aidc/bs-size-guide-mobile-vue2'
import { showToast } from './utils/toastQueue.ts'
import { usePlugins } from './plugins'
import { useMainPrice } from './hook/usePrices'
import monitoring from './hook/monitoring'
import Logger from './utils/logger.ts'
import { getLocalStorage } from '@shein/common-function'
import usePrice from './hook/usePrice.ts'
import { useLockBody } from './utils/use-lock-body'
// import { hot, cold } from './mock.ts'

const lazyImage = '//sheinm.ltwebstatic.com/pwa_dist/images/bg-logo-5ab25d7379.png'

/**
 * size guide 来源映射Map
 */
const sizeGuideFromSceneMap = {
  detail_title: 1,
  detail_pop: 3,
  cart_title: 2,
  cart_pop: 4,
  detail_section: 5,
  detail_tips: 7,
  cart_tips: 8,
}
const appConfigs = useAppConfigs()
const props = defineProps<{
  close: () => void
  fsData?: DS_QuickAddCartStandard.FS_DATA
  dataSource?: DS_QuickAddCartStandard.DataSource
  analysisSource?: AS_QuickAddCartStandard.AnalysisSource
  isIOS18?: boolean
}>()

const analysisInstance = useAnalysis(props?.analysisSource)
const triggerNotice = analysisInstance.triggerNotice
analysisInstance.triggerNotice = async (event) => {
  // 埋点上报前
  const isStops = await usePlugins().hooks('beforeTriggerNotice', event)
  // isStops 数组中有一个返回true就停止执行上报
  if (isStops.some(item => item)) {
    Logger.info('beforeTriggerNotice stop', event)
    return
  }
  triggerNotice(event)
}
provide('analysisInstance', analysisInstance)

const apis = getSource({ appConfigs, dataSource: props?.dataSource })

/**
 * LRU缓存
 * @description 用于缓存商品信息
 * @description 最大缓存10条，缓存时间5分钟
 * @description 用于提升加车弹窗内切换skc时的体验，需要在加车弹窗关闭后清空缓存
 */
const cache = new LRUCache<DS_QuickAddCartStandard.GoodsDetailModules>(10, 1000 * 60 * 5)

const visible = ref<boolean>(false)
const isLoading = ref(false)
const quickSizeRef = ref<any>(null)
const analysisConfig = ref<DS_QuickAddCartStandard.AnalysisConfig>(DEFAULT_ANALYSIS_CONFIG)
const callbacks = ref<DS_QuickAddCartStandard.CallBacks | undefined>(undefined)
const extendedParam = ref<DS_QuickAddCartStandard.ExtendedParam | undefined>(undefined)
const featureConfig = ref<DS_QuickAddCartStandard.FeatureConfig>(DEFAULT_FEATURE_CONFIG)
const salesAttrConfig = ref<DS_QuickAddCartStandard.SalesAttrConfig>(DEFAULT_SALES_ATTR_CONFIG)
const sourceIdentifier = ref<string>('')
const detailUrlExtendParam = ref<DS_QuickAddCartStandard.DetailUrlExtendParam | undefined>(undefined)
const {
  language,
  productInfo,
  saleAttr,
  staticSaleAttr,
  // storeInfo,
  priceInfo,
  couponInfo,
  // userInfo,
  gbCommonInfo,
  productLabel,
  fsAbt,
} = useModules()
const { loadDialog, dialogComponent, dialogRef } = useDialog()

// 源状态 (各个业务按需自取)
const state = reactive({
  skuInfo: null as any,
  quantity: 1,
  quickShip: 0,
  sizeUnit: 'cm',
  switchMallCode: '',
  currentLocalCountry: null as string | null,
  currentLocalUnit: '',
  showImgModal: false,
  imgModalIndex: 0,
  // 用户选择的是否开关大图模式
  userChoseLargeImageMode: false,
  fixedRatio: '', // 商品首图比例
  sizeGuideVisible: false,
  isLogin: false, // 是否登录
  isSwitchColor: false, // 是否切换色块
})

const queryParams = ref<DS_QuickAddCartStandard.GetGoodDetailDataQueryParams | undefined>(undefined)

// 衍生状态
const skuList = computed(() => {
  return saleAttr?.value?.multiLevelSaleAttribute?.sku_list || []
})

// 人气氛围
const atmosphereFlowVisible = computed(() => {
  return visible.value && !state.isSwitchColor && !state.sizeGuideVisible
})
/**
 * 是否售罄
 */
const isSoldOut = computed(() => {
  if (!productInfo || !productInfo?.value) return false
  // 1. 当前商品已经下架
  if (productInfo.value.is_on_sale === '0') return true
  // 2. 全尺码缺货
  if (skuList.value.length > 0 && skuList.value.every(sku => Number(sku.stock) <= 0)) return true
  // 3. 根据选择的销售属性库存判断
  if (skuList.value.length > 0 && state.skuInfo) return Number(state.skuInfo.stock) <= 0
  // 4. 当前商品的库存
  return Number(productInfo.value?.stock) <= 0
})

const onChangeLimitType = (limitType: number) => {
  if (limitType === 2) {
    loadDialog(PromotionLimit).open({
      language: language?.value,
      onClose: () => {
        triggerAddCartComplete()
      },
      onConfirm: () => {
        triggerAddCartComplete()
        monitoring().onMonitoringTrigger({ type: 'click-next-page-public' })
        closeQuickAddCart()
        appConfigs.$router.push('/cart')
      },
    })
    return
  }
  triggerAddCartComplete()
}
/**
 * 是否显示大图模式
 */
const largeImageMode = computed(() => {
  const { selectcolor } = fsAbt?.value || {}
  const { canShowLargeImg } = staticSaleAttr?.value?.mainSaleAttribute || {}

  // 是否支持大图模式
  if (!(canShowLargeImg === '1' && selectcolor?.param?.largeimage === 'on')) {
    return false
  }
  // 根据用户选择了大图模式的缓存值来判断
  return state.userChoseLargeImageMode
})

const setQuantity = (quantity: number) => {
  state.quantity = quantity
  callHook(callbacks.value, 'onQuantityChange', quantity)
}

const setSkuInfo = (skuInfo: any) => {
  state.skuInfo = skuInfo
}

const setQuickShip = status => {
  state.quickShip = status
}

const setSwitchMallCode = (mallCode: string) => {
  state.switchMallCode = mallCode
}

const setCurrentLocalCountry = (country: string | null) => {
  state.currentLocalCountry = country
}

const handleOpenSizeGuide = (scene = '', fromScene) => {
  state.sizeGuideVisible = true
  analysisInstance.triggerNotice({
    id: 'expose_size_guide_detail.comp_quick-add-cart-standard',
    data: {
      from: sizeGuideFromSceneMap[fromScene],
    },
  })
  if (scene === 'SizeSlideTips') {
    //   // 点击尺码信息
    //   analysisInstance.triggerNotice({
    //     id: 'click_size_information.comp_quick-add-cart-standard',
    //   })
  }
}

/**
 * 打开大图模式
 */
const openImgModal = (index: number) => {
  state.imgModalIndex = index
  state.showImgModal = true
}

/**
 * 初始化快速加车组件
 * @param options
 */
function initQuickAddCart(options: DS_QuickAddCartStandard.Options) {
  monitoring().onMonitoringTrigger({ type: 'init-start' })
  analysisConfig.value = options.analysisConfig
  callbacks.value = options.callbacks
  extendedParam.value = options.extendedParam
  detailUrlExtendParam.value = options.detailUrlExtendParam
  salesAttrConfig.value = Object.assign({}, DEFAULT_SALES_ATTR_CONFIG, options.salesAttrConfig || {})
  sourceIdentifier.value = options.sourceIdentifier || ''
  featureConfig.value = options.featureConfig
  state.isLogin = appConfigs.$getters?.isLogin
  state.userChoseLargeImageMode = options.featureConfig.needMainSalesLargeImage || window?.sessionStorage?.getItem('viewColorSelectInLargeImageMode') === 'true'
  state.quantity = extendedParam.value?.quantity || 1
  state.currentLocalCountry = window?.localStorage?.getItem('last_select_country') || ''
  state.currentLocalUnit = window?.localStorage?.getItem('selectedUnit') || ''
  state.switchMallCode = ''
  state.skuInfo = null
  isAddCartSuccess.value = false
  clearModules()
  // 清空缓存
  cache.clear()
  monitoring().onMonitoringTrigger({ type: 'init-end' })
}

const setLoading = (value: boolean) => {
  isLoading.value = value
}

/**
 * 活动来源
 * @description 用于埋点上报
 */
const active_from = computed(() => {
  const popupFrom =
    analysisConfig?.value?.sa?.activity_from || analysisConfig?.value?.sa?.from || analysisConfig?.value?.from || ''
  // TODO 一键购入口返回特殊值
  // if (false) {
  //       return 'one_tap_pay'
  // }
  if (popupFrom) {
    return popupFrom
  }
  // @ts-ignore
  if (window?.SaPageInfo?.page_name) {
    // @ts-ignore
    if (window.SaPageInfo.page_name === 'page_goods_detail') {
      return 'main'
    }
    // @ts-ignore
    if (window.SaPageInfo.page_name === 'page_me') {
      return 'wishlist'
    }
    // @ts-ignore
    if (window.SaPageInfo.page_name === 'page_cart') {
      return 'goods_list'
    }
    // @ts-ignore
    if (window.SaPageInfo.page_name === 'page_collection') {
      return 'wishlist'
    }
    // @ts-ignore
    if (window.SaPageInfo.page_name === 'page_vip_reward') {
      return ''
    }
    // @ts-ignore
    if (window.SaPageInfo.page_name === 'page_birthday_gift_list') {
      return 'page_birthday_gift_list'
    }
    // 试用中心
    // @ts-ignore
    if (window.SaPageInfo.page_name === 'page_free_trial') {
      return 'free_trial'
    }
    // @ts-ignore
    if (window.SaPageInfo.page_name === 'page_advertising') {
      return 'goods_list'
    }
    // @ts-ignore
    if (window.SaPageInfo.page_name === 'page_pre_search') {
      return 'presearch_similar'
    }
  }
  return 'goods_list'
})

/**
 * 曝光快速加车
 */
const exposeQuickAddCart = () => {
  const type = ['skc']
  goodsImgs.value.forEach(item => {
    if (item.is_spu_image && !type.includes('spu')) type.push('spu')
    if (item.sku_code && !type.includes('sku')) type.push('sku')
  })
  const pic_type = type.join(',')
  analysisInstance.triggerNotice({
    id: 'expose_popup_quick_view.comp_quick-add-cart-standard',
    data: {
      goods_list: analysisConfig?.value?.goods_list || genAnalysisGoodsList(),
      activity_from: active_from.value,
      pic_type: pic_type,
      style: analysisConfig?.value?.sa?.style || 'detail',
      ranking_from: analysisConfig?.value?.sa?.ranking_from || '',
      is_attr_fold: analysisConfig?.value?.isAttrFold || '0',
      ...{...analysisConfig?.value?.sa?.passParams || {}},
    },
  })
}

const exposeLowestDays = () => {
  const priceday = priceInfo?.value?.lowestPrice?.days
  if (!priceday) return null
  analysisInstance.triggerNotice({
    id: 'expose_specialprice.comp_quick-add-cart-standard',
    data: {
      priceday
    },
  })
}

const genAnalysisGoodsList = () => {
  const getGoodsListFromTarget = analysisConfig?.value?.getGoodsListFromTarget
  if (typeof getGoodsListFromTarget === 'function') {
    return getGoodsListFromTarget()
  }
  const otherMark = [] // 其他标示
  const sourceTarget = analysisConfig?.value?.sourceTarget
  let saleAttr = ''
  let labelCarousels = ''
  if (sourceTarget) {
    saleAttr = sourceTarget.getAttribute('data-sale-attr') || ''
    labelCarousels = sourceTarget.getAttribute('data-label-carousel') || ''
  }
  const goods_list = [
    productInfo?.value?.goods_id,
    productInfo?.value?.goods_sn,
    productInfo?.value?.productRelationID,
    (analysisConfig?.value?.index ?? 0) + 1,
    1,
    1,
    '',
    '',
    `pri_${priceInfo?.value?.salePrice?.usdAmount}|pri_${priceInfo?.value?.retailPrice?.usdAmount}`,
    otherMark.join('|'),
    saleAttr,
    'mall_' + mallCode.value,
    '',
    labelCarousels,
  ].join('`')
  return goods_list
}

const enableLock = ref(false)
const openQuickAddCart = async (params: DS_QuickAddCartStandard.GetGoodDetailDataQueryParams) => {
  if (props.isIOS18) {
    enableLock.value = true
    const currentScrollTop = document.documentElement.scrollTop || document.body.scrollTop
    nextTick(() => {
      window.scrollTo(0, currentScrollTop + 1)
    })
  }
  visible.value = true
  // 保存查询参数（用于后续切换skc和mallCode的时候，可以获取初始查询参数）
  queryParams.value = params
  const status = await getGoodsDetailFlow(params, cache)
  // 曝光快速加车
  exposeQuickAddCart()
  exposeLowestDays()
  return status
}

const closeQuickAddCart = () => {
  visible.value = false
}

const priceTipscloseQuickAddCart = () => {
  monitoring().onMonitoringTrigger({ type: 'click-next-page-public' })
  closeQuickAddCart()
}

// 点击到手价
const onHandlerClickEstimatedTag = () => {
  callHook(callbacks.value, 'onPriceClick')
}

/**
 * 获取商品详情流程
 * @param params
 * @param cache
 */
async function getGoodsDetailFlow(
  params: DS_QuickAddCartStandard.GetGoodDetailDataQueryParams,
  cache: LRUCache<DS_QuickAddCartStandard.GoodsDetailModules>,
) {
  // 固定参数
  params.isUserSelectedMallCode = 1
  const goodsDetail = await getGoodsDetailData(params, cache)
  // 获取商品首图比例 切换skc不需要变化比例
  if (!state.isSwitchColor) {
    state.fixedRatio = await getGoodsFirstImgRatio(extendedParam.value, goodsDetail?.coldModules?.productInfo)
  }
  if (goodsDetail) {
    updateModules(goodsDetail)
  }

  return goodsDetail ? 'success' : 'error'
}

/**
 * 刷新商品详情
 * @description 清空缓存，重新获取商品详情
 */
function refreshGoodsDetails() {
  Logger.info('refreshGoodsDetails:', productInfo?.value?.goods_id)
  cache.clear()
  // 重新获取商品详情
  if (productInfo?.value?.goods_id) {
    const params = { ...queryParams.value, goods_id: productInfo?.value?.goods_id, mallCode: mallCode.value }
    getGoodsDetailFlow(params, cache)
  }
}

async function getGoodsFirstImgRatio(
  extendedParam?: DS_QuickAddCartStandard.ExtendedParam,
  productInfo?: DS_QuickAddCartStandard.ProductInfo,
) {
  if (extendedParam?.imgRatio) {
    return extendedParam.imgRatio
  } else if (extendedParam?.mainImg) {
    // 没有传比例时需要重新计算容器比例
    return getImageRatio(extendedParam?.mainImg)
  } else {
    const { currentSkcImgInfo } = productInfo || {}
    const { spuImages = [], skcImages = [] } = currentSkcImgInfo || {}
    const imgUrl = spuImages[0] || skcImages[0] || ''
    return getImageRatio(imgUrl)
  }
}

/**
 * 校验外部缓存
 */
function verifyExternalCache(data: DS_QuickAddCartStandard.GoodsDetailModules) {
  const isValid = data?.coldModules?.productInfo && data?.hotModules?.saleAttr && data?.otherModules?.detailImages && data?.abtModules
  if(!isValid) {
    Logger.info('verifyExternalCache fail:', data)
  }
  return isValid
}

/**
 * 获取商品详情
 * @param goods_id
 * @returns
 */
async function getGoodsDetailData(
  params: DS_QuickAddCartStandard.GetGoodDetailDataQueryParams,
  cache: LRUCache<DS_QuickAddCartStandard.GoodsDetailModules>,
) {
  // 外部缓存
  const externalCache = await usePlugins().hook('beforeGetGoodsDetailData', params)
  if (externalCache && verifyExternalCache(externalCache)) {
    return externalCache
  }
  // 内部缓存
  let cacheKey = params.mallCode ? `${params.goods_id}_${params.mallCode}` : undefined
  if (cacheKey && cache.has(cacheKey)) {
    return cache.get(cacheKey)
  } else {
    try {
      let { showEstimatedPrice, closeEstimatedAndAbPrice } = featureConfig.value || {}
      let { goods_id, mallCode, billno = '' } = params || {}
      let commonParams = {
        goods_id,
        isUserSelectedMallCode: 1,
        isHideEstimatePriceInfo: showEstimatedPrice ? 0 : 1,
        specialSceneType: closeEstimatedAndAbPrice ? 1 : 0,
      }
      let staticParams: DS_QuickAddCartStandard.GetGoodDetailDataQueryParams = {
        // 静态接口的mall code就是用下划线
        mall_code: mallCode,
        ...commonParams,
      }
      // 获取物流信息
      let addressCookie = getLocalStorage('addressCookie') || {}
      let address_cache = getLocalStorage('address_cache') || {}
      let { addressId, countryId, stateId, cityId, districtId } = addressCookie
      let state = address_cache[stateId]
      let city = address_cache[cityId]
      let district = address_cache[districtId]
      let shippingParams = {
        countryId,
        addressId,
        state,
        city,
        district,
        stateId,
        cityId,
      }
      let realParams: DS_QuickAddCartStandard.GetGoodDetailDataQueryParams = {
        mallCode,
        billno,
        isQueryIsPaidMember: 1,
        isQueryCanTrail: appConfigs.$getters?.isLogin ? 1 : 0,
        ...commonParams,
        ...shippingParams,
        ...params,
      }
      setLoading(true)
      monitoring().onMonitoringTrigger({ type: 'get-data-start' })
      const [staticData, realtimeData, detailImages, abtData] = await Promise.all([
        apis.getGoodDetailStaticData(staticParams),
        apis.getGoodDetailRealTimeData(realParams),
        apis.getGoodDetailImageData(realParams),
        apis.getGoodDetailAbtInfo(staticParams.goods_id),
      ])
      // staticData.info = cold
      // realtimeData.info = hot
      // abtData.info = hot.webProductDetailAbtInfo
      monitoring().onMonitoringTrigger({ type: 'get-data-end' })
      setLoading(false)
      // 非静/动态数据
      const otherModules = {
        // 细节图
        detailImages: detailImages?.info?.goods_images || [],
      }
      if (abtData?.code !== '0' || !abtData?.info) {
        console.error('getGoodDetailAbtInfo error:', abtData)
      }
      if (staticData.info && realtimeData.info) {
        cacheKey = cacheKey || `${goods_id}_${staticData.info.mallInfo?.selectedMallCode}`
        cache.set(cacheKey, { coldModules: staticData.info, hotModules: realtimeData.info, otherModules, abtModules: abtData.info })
        return { coldModules: staticData.info, hotModules: realtimeData.info, otherModules, abtModules: abtData.info }
      } else {
        if (!staticData.info) {
          handleGetGoodsDetailDataFail(staticData)
        } else {
          handleGetGoodsDetailDataFail(realtimeData)
        }
        return null
      }
    } catch (error: any) {
      setLoading(false)
      handleGetGoodsDetailDataFail(error)
      return null
    }
  }
}

function handleGetGoodsDetailDataFail(error: any, onOpenFail?: (e: any) => void) {
  closeQuickAddCart()
  loadDialog(AddCartFailDialog).open({
    onClose: onCloseCartTip,
    msg: 'Product does not exist',
    duration: 1000,
  })
  let msg = ''
  if (Object.prototype.toString.call(error) == '[object Object]') {
    if(error?.code && error?.msg) {
      msg = `${error?.code} -- ${error?.msg}`
    } else if (error?.config?.url || error?.message) {
      msg = `${error?.config?.url || 'no url'} -- ${error?.message || 'unknown error'}`
    } else {
      msg = JSON.stringify(error) || 'JSON.stringify error'
    }
  } else {
    msg = typeof error === 'string' ? error : 'unknown error'
  }

  monitoring().onMonitoringTrigger({ type: 'get-data-error', status: 'fail', msg })

  console.log('quick add cart get good detail data fail:', error)

  callHook(onOpenFail ? { onOpenFail } : callbacks.value, 'onOpenFail', error)
}

const mallCode = computed(() => {
  const { selectedMallCode } = productInfo?.value || ({} as any)
  return state.switchMallCode || selectedMallCode
})

const currentStock = computed(() => {
  const { is_on_sale, stock } = (productInfo?.value as any) || {}
  const { sku_code, mall_stock } = (state.skuInfo as any) || {}
  if (is_on_sale != '1') return 0
  if (sku_code) {
    return Number(mall_stock?.find?.(i => i?.mall_code == mallCode.value)?.stock) || 0
  }
  return Number(stock)
})

const willSoldOutTipsInfo = computed(() => {
  const { sku_code } = (state.skuInfo as any) || {}
  const { multiLevelSaleAttribute } = saleAttr?.value || {}
  const { unselected_will_sold_out_tips, lowStockType } = multiLevelSaleAttribute || {}
  if (sku_code) {
    const { selected_will_sold_out_tips, lowStockType } = (state.skuInfo as any) || {}
    return {
      tip: selected_will_sold_out_tips || '',
      lowStockType: lowStockType,
      scene: 'sku',
    }
  }
  return {
    tip: unselected_will_sold_out_tips || '',
    lowStockType,
    scene: 'skc',
  }
})

const mainPrice = computed(() =>
  useMainPrice({
    skcPriceInfo: priceInfo?.value,
    skuPriceInfo: state.skuInfo?.priceInfo,
    estimatedInfo,
    isShowPrice: isShowPrice.value,
  }),
)
/**
 * 是否商详页主商品
 */
const isMainProduct = computed(() => productInfo?.value?.goods_id === featureConfig?.value?.mainPageGoodsId)

const sizeAttrInfo = computed(() => {
  const skcSaleAttr = staticSaleAttr?.value?.multiLevelSaleAttribute?.skc_sale_attr || []
  return skcSaleAttr.find(item => item?.isSize === '1') || {}
})

const defaultCountryCode = computed(() => {
  // 默认本地化国家 => 当前站点的国家
  const { attr_value_list } = sizeAttrInfo.value || {}
  return attr_value_list?.find?.(item => !!item.country_code)?.country_code || ''
})

const currentCountry = computed(() => {
  if (state.currentLocalCountry === 'default') return ''
  if (state.currentLocalCountry) return state.currentLocalCountry
  const defaultlocalsize = fsAbt?.value?.defaultlocalsize?.param?.defaultlocalsize || 'old'
  if (defaultlocalsize === 'new' || defaultlocalsize === 'default_size_no_default') return ''

  return defaultCountryCode.value || ''
})

const currentUnit = computed(() => {
  const { defaultSizeUnit } = sizeAttrInfo.value || {}
  return (state.currentLocalUnit as 'cm' | 'inch') || (defaultSizeUnit != '1' ? 'cm' : 'inch')
})
const triggerAddCartComplete = () => {
  callHook(callbacks.value, 'onAddCartComplete', {
    target: analysisConfig.value?.sourceTarget,
    isSuccess: isAddCartSuccess.value,
    featureConfig: featureConfig.value,
  })
}
const onCloseCartTip = () => {
  triggerAddCartComplete()
  if (isAddCartSuccess.value) {
    if (typeof window.addToBagSuccess === 'function') {
      window.addToBagSuccess({
        target: analysisConfig.value?.sourceTarget,
      })
    }
  }
}
const checkSelectSize = () => {
  return quickSizeRef.value?.checkSelectSize?.()
}

const actQuickAdd = act_id => {
  const { from, biClickReport, mod } = analysisConfig?.value || {}
  if (from === 'activityQuickAdd') {
    if (+act_id === 6 || +act_id === 7) {
      biClickReport?.({
        act_id,
        mod, // 公共字段
      })
    }
  }
}

const handleJumpDetailsPage = (rawUrl: string) => {
  monitoring().onMonitoringTrigger({ type: 'click-next-page-public' })
  closeQuickAddCart()
  // 点击跳转详情页回调
  callbacks.value?.onJumpDetailClick?.({
    goods_id: productInfo?.value?.goods_id as string,
    goods_img: productInfo?.value?.goods_img as string,
    isAddCart: isAddCartSuccess.value,
    isWish: Boolean(productInfo?.value?.is_saved) || false,
    rawUrl,
  })
  // 自定义跳转详情页回调
  if (callbacks.value?.onJumpDetail) {
    callbacks.value?.onJumpDetail?.({
      goods_id: productInfo?.value?.goods_id as string,
      goods_img: productInfo?.value?.goods_img as string,
      isAddCart: isAddCartSuccess.value,
      isWish: Boolean(productInfo?.value?.is_saved) || false,
      rawUrl,
    })
  } else {
    // 默认跳转详情页
    featureConfig.value?.needClickToDetail && useAppConfigs().$router.push(rawUrl)
  }
}

const handleCompose = params => {
  const { skuInfo, isUserSelect, curSelectAttr, attrs } = params
  setSkuInfo(skuInfo)
  if (isUserSelect && curSelectAttr?.attr_id == '87') {
    actQuickAdd(7)
  }

  const curGoodsId = productInfo?.value?.goods_id as string
  const sizeInfo = attrs?.find?.(item => item.attr_id == 87) || {}
  callHook(callbacks.value, 'onSubAttrChange', {
    type: isUserSelect ? 'select' : 'default',
    value: {
      curGoodsId,
      sizeInfo,
      isAttrFold: analysisConfig?.value?.isAttrFold || '0',
      ...params,
    },
  })

  // sku 图自动选中
  if (skuList.value?.length > 0) {
    const index = goodsImgs.value?.findIndex(i => i.sku_code === skuInfo?.sku_code)
    index && index > -1 && gallerySwiper.value && gallerySwiper.value?.getStart(index, 500)
  }
  // 人气氛围刷新高度
  setTimeout(() => {
    quickAtmosphereFlowRef.value && quickAtmosphereFlowRef.value?.refreshAtmosphereHeight()
  }, 300)
}
const handleMallClick = mallInfo => {
  const { mallCode } = mallInfo || {}
  if(mallCode) {
    setSwitchMallCode(mallCode)
    callHook(callbacks.value, 'onMallClick', mallInfo)
    const params = {
      ...queryParams.value,
      goods_id: productInfo?.value?.goods_id as string,
      mallCode,
    }
    getGoodsDetailFlow(params, cache)
  } else {
    Logger.error('handleMallClick Exception, mallCode is an outlier. mallInfo:', mallInfo)
  }
}

const handleLocalChange = val => {
  setCurrentLocalCountry(val)
  callHook(callbacks?.value, 'onSubAttrClick', {
    type: 'localChange',
    value: val,
  })
}

const handleSizeUnitChange = val => {
  state.currentLocalUnit = val
}

const onLoginStatusChange = () => {
  state.isLogin = appConfigs.$getters?.isLogin
}

const onWishStatusChange = (status: string) => {
  updateProductWishStatus(status)
  // 清除缓存
  const cacheKey = productInfo?.value?.goods_id
  cache.deleteBySimilarKey(cacheKey)
  // 外层回调
  callHook(callbacks.value, 'onFavoriteStatusChange', { wishStatus: status === '1' })
}

const { skcEstimatedInfo, estimatedInfo } = useEstimatedInfo({
  isSoldOut,
  currentStock,
  featureConfig,
  skuInfo: computed(() => state.skuInfo),
})

const { isShowPrice, price } = useCommonInfo({
  featureConfig,
  isSoldOut,
  currentStock,
  skuInfo: computed(() => state.skuInfo),
})

const isNEstimated = computed(() => {
  return ESTIMATED_DATA_TYPES.N_ESTIMATED == skcEstimatedInfo?.value?.dataType
})

const buyMultipleText = computed(() => {
  if (isNEstimated.value) {
    const { buySaveMultiLang, buySaveMoreMultiLang } = price?.value?.npcsEstimatedPriceInfo || {}
    return state.quantity <= skcEstimatedInfo?.value?.purchasePcs ? buySaveMultiLang || '' : buySaveMoreMultiLang || ''
  }
  return ''
})

const qtySelectShow = computed(() => {
  const { cccDetailsTemplate } = (productInfo?.value) || {}
  const { countCell } = cccDetailsTemplate?.content?.[0] || {}
  // 需要展示数量选择器
  if (!featureConfig?.value?.needQtySelect) return false
  // n件到手价展示数量选择器
  if (isNEstimated.value) return true
  const sizechartshow = fsAbt?.value?.sizechartshow?.param?.sizechartshow || ''
  const isCccTemplate = !['A', 'B'].includes(sizechartshow)
  return sizechartshow === 'B' || (isCccTemplate && countCell == '1')
})

const stockTipShowLocation = computed(() => {
  if (qtySelectShow.value) {
    return 'qty'
  }
  return 'line'
})

/** 商品主图 */
const { goodsImgs } = useGoodsImgs()

/** 主销售属性模型 */
const { mainSalesAttrConfig, mainSalesAttrData } = useMainSalesAttrModules({
  featureConfig,
  analysisConfig,
  largeImageMode,
})
const isAddCartSuccess = ref<boolean>(false)

const gallerySwiper = ref<any>(null)
const quickAtmosphereFlowRef = ref<any>(null)

// 这里和价格组件里面的逻辑一样，属于重复计算了，后续可以优化
const priceContext = computed(() => {
  if (productInfo?.value && priceInfo?.value) {
    return usePrice({
      featureConfig,
      skuInfo: state.skuInfo,
      skuList,
      isSoldOut,
      currentStock})
  }
  return null
})
const customPriceComponent = computed(() => {
  if (priceInfo?.value) {
    return {
      functional: true,
      render: () => usePlugins().hook('renderPrice', priceContext.value as unknown as DS_QuickAddCartStandard.PriceContext),
    }
  }
  return null
})

const belowSalesAttrContext = computed(() => ({
  quantity: state.quantity,
  skuInfo: state.skuInfo,
  mallCode: state.switchMallCode,
  isSoldOut: isSoldOut.value,
  mallStock: currentStock.value,
}))

/**
 * 销售属性下方插槽
 */
const belowSalesAttrSlot = computed(() => {
  return {
    render: () => usePlugins().hook('renderBelowSalesAttr', belowSalesAttrContext),
  }
})

/**
 * 主销售属性切换回调
 */
const onHandlerMainAttrChange = async (val: DS_MainSalesAttr.MainSaleAttributeInfo, immediateUpdate?: boolean) => {
  // 专题活动（color： 6）
  actQuickAdd(6)
  state.isSwitchColor = true
  const mallCodeVal = mallCode.value
  // 需要清空一下mallCode，以接口回来的mallCode为准
  state.switchMallCode = ''
  const params = {
    ...queryParams.value,
    goods_id: val?.goods_id,
    mallCode: mallCodeVal,
  }
  await getGoodsDetailFlow(params, cache)
  nextTick(() => {
    state.isSwitchColor = false
    const curSkcIndex = goodsImgs.value?.findIndex(i => !i.is_spu_image)
    const index = curSkcIndex > -1 ? curSkcIndex : 0
    gallerySwiper.value && gallerySwiper.value?.getStart(index, 300)
  })
  // 外层回调
  callHook(callbacks.value, 'onMainAttrChange', val, immediateUpdate)
}

/**
 * 大小图切换回调
 */
const onHandlerLargeImageChange = (val: boolean) => {
  state.userChoseLargeImageMode = val
  window?.sessionStorage?.setItem('viewColorSelectInLargeImageMode', val)
}

const handleClickSizeGroupItem = item => {
  const { goodsId, goodsName } = item || {}
  if (goodsId === productInfo?.value?.goods_id) return
  onHandlerMainAttrChange({
    goods_id: goodsId,
    curr_goods_id: productInfo?.value?.goods_id,
    fromSizeGroup: true,
    // 以下数据提供给切换色块回调this.config.clickCallBack['changeColor']
    goods_name: goodsName,
    goods_url_name: goodsName,
  }, true)
}

// 加车后触发这个自动领到手价最优券的方法
const autoGetCoupon = (needToast: boolean) => {
  const isLogin = appConfigs.$getters?.isLogin
  if (!isLogin) return
  if (!estimatedInfo?.value) return

  /**
   * 符合条件可以领取的优惠券列表
   */
  let couponCodes: string[] = []
  couponInfo?.value?.forEach(item => {
    const { couponStatus, businessExtension, coupon } = item || {}
    const { isBindingCoupon, isFreeShipping, isFreeShippingThreshold, isOptimalCoupon } =
      businessExtension?.productDetail || {}
    // 未领取并且已绑定的优惠券
    if (couponStatus === 2 && isBindingCoupon === '1') {
      // 免邮券或者最优券
      if ((isFreeShipping && isFreeShippingThreshold) || isOptimalCoupon) {
        couponCodes.push(coupon)
      }
    }
  })

  if (!couponCodes.length) return

  // 请求领取优惠券接口，进行券绑定
  apis
    .postCouponBind({
      coupon_code: couponCodes,
    })
    .then(res => {
      const {
        info: { failureList = [], successList = [] },
        code,
      } = res

      let isSuccess = true
      if (code !== '0') {
        isSuccess = false
      }
      if (successList.length !== couponCodes.length) {
        isSuccess = false
      }
      for (let i of successList) {
        if (!couponCodes.includes(i.couponCode)) {
          isSuccess = false
        }
      }
      if (needToast) {
        if (isSuccess) {
          showToast({
            duration: 1500,
            content: language?.value?.SHEIN_KEY_PWA_25104 || 'We automatically help you to claim coupons',
            iconClass: 'suiiconfont sui_icon_coupon_discount_16px',
          })
        } else {
          let code = failureList?.find?.(item => couponCodes.includes(item.couponCode))?.code
          if (code) {
            showToast({
              content: language?.value?.[code],
              duration: 1500,
            })
          }
        }
      }
      // 领券后回调
      callHook(callbacks.value, 'onCouponBindFinish', {
        res,
        couponCodes,
        isSuccess,
        skuInfo: state.skuInfo,
        mallCode: mallCode.value,
        productInfo: productInfo?.value,
      })
    })
}
const loadCartSuccess = () => {
  closeQuickAddCart()
  loadDialog(AddCartSuccessDialog).open({
    onClose: onCloseCartTip,
    language: language?.value,
    duration: 1000,
  })
}
const loadCartFail = (errorMsg?: string) => {
  loadDialog(AddCartFailDialog).open({
    onClose: onCloseCartTip,
    msg: errorMsg || 'error',
    language: language?.value,
    duration: 1500,
  })
}

function genAddCartSnapshot(addCartParams: DS_QuickAddCartStandard.PostAddToCartParams) {
  const { sku_code } = addCartParams
  return {
    sku_code,
    skc: productInfo?.value?.goods_sn,
    spu_id: productInfo?.value?.productRelationID,
    mainPrice: mainPrice.value,
    goods_id: productInfo?.value?.goods_id,
    quantity: addCartParams.quantity,
    mall_code: addCartParams.mall_code,
  } as DS_QuickAddCartStandard.AddCartSnapshot
}

const onAddCartFinished = (
  { isSuccess, errorMsg, cartTotalQuantity, isHiddenDefaultTip }: IHandlerResult,
  addCartParams: DS_QuickAddCartStandard.PostAddToCartParams,
) => {
  isAddCartSuccess.value = isSuccess
  setLoading(false)
  const snapshot = genAddCartSnapshot(addCartParams)
  callHook(callbacks.value!, 'onAddCartFinished', {
    isSuccess,
    errorMsg,
    cartTotalQuantity,
    addCartSnapshot: snapshot,
  })
  // 加车成功后，获取购物车信息
  if (isSuccess) {
    apis
      .getCartInfo()
      .then(res => {
        callHook(callbacks.value!, 'onCartInfoReceived', res)
      })
      .catch(err => {
        Logger.error('getCartInfo error:', err)
        callHook(callbacks.value!, 'onCartInfoReceived', { code: '-1', msg: err })
      })
  }

  // 加车后, 无论成功失败都会触发自动领券
  if (featureConfig.value?.needAutoGetCoupon) {
    autoGetCoupon(isSuccess)
  }
  if (isHiddenDefaultTip) {
    isSuccess && closeQuickAddCart()
    return
  }
  isSuccess ? loadCartSuccess() : loadCartFail(errorMsg)
}

const onAddCartBefore = () => {
  setLoading(true)
}

watch(
  () => visible.value,
  async (val) => {
    callHook(callbacks.value, 'onDrawerStatusChange', val)
    if (!val) {
      monitoring().onMonitoringTrigger({ type: 'close', status: isAddCartSuccess.value })
    }
  },
)

watch(
  () => state.isLogin,
  _ => {
    // 登陆状态变更，清空缓存
    refreshGoodsDetails()
  },
)

const router = ref(useAppConfigs().$router)
const routeName = computed(() => {
  // @ts-ignore
  return appConfigs?.$router?.router?.currentRoute?.name
})

// 单独 watch routeName，无法触发。所以需要额外watch router
watch(
  [() => router.value, () => routeName.value],
  ([_new, newRouteName],[_old, oldRouteName]) => {
    // 路由变更，关闭快速加车
    if(newRouteName !== oldRouteName) {
      closeQuickAddCart()
      state.sizeGuideVisible = false
    }
  },
  {
    deep: true,
  },
)

useLockBody(visible, enableLock)

defineExpose({
  refreshGoodsDetails,
  openQuickAddCart,
  closeQuickAddCart,
  initQuickAddCart,
  loadCartSuccess,
  loadCartFail,
  setLoading,
  saleAttr,
  handleGetGoodsDetailDataFail,
})
</script>

<style lang="less">
// .bsc-quick-add-cart__toolbar-placeholder.ios-safe-bottom {
//   height: 30px;
// }
.bsc-quick-add-cart-standard {
  &__mask {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99999;
  }
  &-drawer {
    .sui-drawer__body {
      display: grid;
    }
  }

  &__toast {
    font-size: 12px;
  }

  &__wrapper.S-drawer {
    width: 100%;
    height: 100vh;
    height: -webkit-fill-available;
  }

  .S-dialog__normal {
    &.small-tips {
      width: 58.7vw;
      min-height: 33.3vw;
    }
  }

  .quick-add__new-tips {
    text-align: center;
    padding-bottom: 4.7vw;
    font-size: 12px;
    line-height: 1.5;

    .sui-icon-common__wrap {
      margin-bottom: 2vw;
      padding: 3vw;
    }

    &>p {
      color: #222;
      line-height: 1.5;
      font-size: 12px;
    }
  }
}

.bsc-quick-add-cart {
  display: flex;
  flex-direction: column;
  background: #fff;
  overflow: hidden;
  max-height: 80vh;
  /* 兼容safari */
  height: -webkit-fill-available;
  z-index: 10;

  &__toolbar-placeholder {
    width: 100%;
    transition: all 0.5s;
    height: calc(2 * constant(safe-area-inset-bottom)); /* 兼容 iOS < 11.2 */
    height: calc(2 * env(safe-area-inset-bottom)); /* 兼容 iOS >= 11.2 */
  }

  &__coupontoast {
    font-size: 12px;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1.067vw;
    padding: 4px;
  }

  &__header {
    display: flex;
    padding: 2px;
    background: #fff;
    justify-content: flex-end;

    .bsc-quick-add-cart__close-btn {
      padding: 4px;
    }
  }

  &__body {
    background: #fff;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    position: relative;
    // min-height: 120vw;
    max-height: 80vh;
    overflow: hidden;

    ::-webkit-scrollbar {
      width: 0px;
    }

    -webkit-overflow-scrolling: touch;
  }

  &__content {
    padding: 0 3.2vw;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    overflow-x: hidden;

    // 留白占位
    .blank-placeholder {
      width: 100%;
      padding-bottom: 26.8vw;
    }
  }
}
</style>
